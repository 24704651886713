define("variablo/controllers/index", ["exports", "ember"], function (exports, _ember) {

	/* Helper functions */

	function makeSequentialArray(number) {
		var myArray = [];

		for (var i = 0; i < number; i++) {
			myArray.push(i);
		}

		return myArray;
	}

	function setCurrentSelectionArray(newSelection, currentSelectionArray, columns) {
		var c = [];
		for (var i = 0; i < columns; i++) {

			var guess = undefined;

			if (currentSelectionArray) {
				guess = currentSelectionArray[i].guess;
			} else {
				guess = "unset";
			}

			c.push({
				selected: newSelection == i, // true or false
				guess: guess
			});
		}
		return c;
	}

	function guessPickerAllPicked(currentSelectionArray) {
		var allPicked = true;

		for (var i = 0; i < currentSelectionArray.length; i++) {

			if ("unset" === currentSelectionArray[i].guess) {
				allPicked = false; /* at least one column has no pick yet */
			}
		}

		return allPicked;
	}

	function createRandomCode(columns, colors) {
		/* Create random code (that needs to be guessed) */
		var code = [];

		for (var i = 0; i < columns; i++) {
			var r = Math.floor(Math.random() * colors);
			code.push(r);
		}

		// TEST
		console.log(code);

		return code;
	}

	function setupNewGame(t) {

		/* set global variables for new game */
		t.set('isNavOpen', false);
		t.set('colors', t.get('settings.colors.value'));
		t.set('columns', t.get('settings.columns.value'));
		t.set('colorsArray', makeSequentialArray(t.get('settings.colors.value')));
		t.set('columnsArray', makeSequentialArray(t.get('settings.columns.value')));
		t.set('currentRound', 1);
		t.set('currentSelection', 0);
		t.set('currentSelectionArray', setCurrentSelectionArray(0, false, t.get('settings.columns.value')));
		t.set('code', createRandomCode(t.get('settings.columns.value'), t.get('settings.colors.value')));
		t.set('answers', []);
		t.set('gameOver', false);
		t.set('gameWon', false);
		t.set('guessPickerAllPicked', false);
		t.set('guessPickerAutoAdvance', true);
		t.set('settings.settingsHaveChanged', false);
		t.set('timeStart', Date.now());
		t.set('timeEnd', false);
	}

	exports["default"] = _ember["default"].Controller.extend({

		/* Initital Settings */
		settings: { // This object can be changed via the navigation screen
			colors: {
				value: 6,
				"default": 6,
				min: 2,
				max: 8,
				"switch": false
			},
			columns: {
				value: 3,
				"default": 3,
				min: 3,
				max: 6,
				"switch": false
			},
			settingsHaveChanged: false },

		// If settings change, a note will show that they take effect the next game
		/* INITIALIZE */
		init: function init() {
			setupNewGame(this);
		},

		/* ACTIONS */
		actions: {

			/**
    * ACTION:
    * Toogle navigation
    */
			toggleNav: function toggleNav() {
				this.toggleProperty('isNavOpen');
			},

			/**
    * ACTION:
    * Guess Picker - Select Column
    */
			guessPickerSelectColumn: function guessPickerSelectColumn(selectedColumn) {
				var columns = this.get('columns');
				var currentSelectionArray = this.get('currentSelectionArray');

				/* set global variables */
				this.set('currentSelectionArray', setCurrentSelectionArray(selectedColumn, currentSelectionArray, columns));
				this.set('currentSelection', selectedColumn);
				this.set('guessPickerAutoAdvance', false); // since user namually selected a column, don't auto advance anymore this round
			},

			/**
    * ACTION:
    * Guess Picker - Pick Column Guess
    */
			guessPickerPickColumnGuess: function guessPickerPickColumnGuess(color) {
				var columns = this.get('columns');
				var currentSelectionArray = this.get('currentSelectionArray');
				var currentSelection = this.get('currentSelection');

				// Add new color to array
				_ember["default"].set(currentSelectionArray[currentSelection], 'guess', color);

				// if autoAdvance, increase column (so that next is chosen automatically)
				if (this.get('guessPickerAutoAdvance')) {
					if (currentSelection < columns - 1) {
						currentSelection++;
					}
				}

				/* set global variables */
				this.set('currentSelectionArray', setCurrentSelectionArray(currentSelection, currentSelectionArray, columns));
				this.set('currentSelection', currentSelection);
				this.set('guessPickerAllPicked', guessPickerAllPicked(currentSelectionArray));
			},

			/**
    * ACTION:
    * Evaluate guess
    */
			evaluateGuess: function evaluateGuess() {

				var code = this.get('code');
				var columns = this.get('columns');
				var currentSelectionArray = this.get('currentSelectionArray');
				var currentAnswers = this.get('answers');

				var guess = [];
				for (var i = 0; i < columns; i++) {
					guess.push(currentSelectionArray[i].guess);
				}

				var codeArray = code.slice(); // slice clones the array
				var guessArray = guess.slice(); // slice clones the array
				var hint = [];

				var gameWon = true; // will be set to false below if there aren't all black results

				// Check for correct position (black)			
				for (var i = 0; i < codeArray.length; i++) {
					if (guessArray[i] == codeArray[i]) {
						// Correct position
						hint.push('correct-position');
						codeArray[i] = "x"; // Mark that color since we've dealt with it
						guessArray[i] = "-"; // Mark that guess since we've dealt with it
					} else {
							gameWon = false; // game not over because at least one hint is not black
						}
				}

				// Check for correct color in any position (white)
				for (var i = 0; i < codeArray.length; i++) {
					for (var j = 0; j < codeArray.length; j++) {
						if (guessArray[i] == codeArray[j]) {
							// Correct color in any position
							hint.push('correct-color');
							codeArray[j] = "x"; // Mark that color since we've dealt with it
							guessArray[i] = "-"; // Mark that guess since we've dealt with it
							break; // done with this loop
						}
					}
				}

				// Check for incorrect colors (empty)
				for (var i = 0; i < codeArray.length; i++) {
					if ("x" != codeArray[i]) {
						// All code arrays that are not crossed out are completely incorrect
						hint.push('incorrect');
					}
				}

				currentAnswers.pushObject({
					guess: guess,
					hint: hint
				});

				var gameOver = false;
				var timeEnd = false;
				if (gameWon) {
					gameOver = true;
					timeEnd = Math.round((Date.now() - this.get('timeStart')) / 1000);
				}

				/* Increase round number if game is not over */
				var currentRound = this.get('currentRound');
				if (!gameOver) {
					currentRound++;
				}

				/* set global variables */
				this.set('currentSelectionArray', setCurrentSelectionArray(0, false, columns));
				this.set('currentSelection', 0);
				this.set('guessPickerAllPicked', false);
				this.set('guessPickerAutoAdvance', true);
				this.set('answers', currentAnswers);
				this.set('currentRound', currentRound);
				this.set('gameWon', gameWon);
				this.set('gameOver', gameOver);
				this.set('timeEnd', timeEnd);
			},

			/**
    * ACTION:
    * New game
    */
			newGame: function newGame() {
				setupNewGame(this);
			},

			/**
    * ACTION:
    * Toggle Nav Option
    */
			navToggleOption: function navToggleOption(option) {
				var settings = this.get('settings');

				var s = !settings[option]["switch"]; // toggle switch

				_ember["default"].set(settings[option], 'switch', s); // Set new switch value

				if (!s) {
					_ember["default"].set(settings[option], 'value', settings[option]["default"]); // if switch is off, set value to default
				}

				_ember["default"].set(settings, 'settingsHaveChanged', true);

				this.set('settings', settings);
			},

			/**
    * ACTION:
    * validate Nav Options
    */
			/* Colors */
			navValidateOptionColor: function navValidateOptionColor() {
				var settings = this.get('settings');

				var value = settings.colors.value;

				if (value < settings.colors.min || settings.colors.max > 8 || isNaN(value)) {
					value = settings.colors["default"];
				}

				_ember["default"].set(settings.colors, 'value', value);
				_ember["default"].set(settings, 'settingsHaveChanged', true);

				this.set('settings', settings);
			},
			/* Columns */
			navValidateOptionColumns: function navValidateOptionColumns() {
				var settings = this.get('settings');

				var value = settings.columns.value;

				if (value < settings.columns.min || value > settings.columns.max || isNaN(value)) {
					value = settings.columns["default"];
				}

				_ember["default"].set(settings.columns, 'value', value);
				_ember["default"].set(settings, 'settingsHaveChanged', true);

				this.set('settings', settings);
			}
		}
	});
});
/**
 * 
 * NAMING CONVENTIONS
 * ==================
 * 
 * ELEMENTS:
 * 
 * Code
 * 
 * Round
 *   Guess
 *     Column
 *     SelectedColumn
 *   Hint
 * 
 * GuessPicker
 *   guessPickerAllPicked
 *   currentSelection
 *   guessPickerAutoAdvance
 * 
 * Color
 * Shape
 * 
 * FUNCTIONS:
 * 
 * toggleNav()
 * guessPickerSelectColumn()
 * guessPickerPickColumnGuess()
 * evaluateGuess()
 * newGame()
 * 
 */