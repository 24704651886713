define('variablo/helpers/pluralize', ['exports', 'ember'], function (exports, _ember) {
	exports.pluralize = pluralize;

	function pluralize(args) {

		var number = args[0];
		var singular = args[1];
		var plural = args[2];

		if (number > 1) {

			if (!singular || !plural) {
				return 's'; // Add an 's'
			} else {
					return plural;
				}
		} else {

			if (singular) {
				return singular;
			}
		}
	}

	exports['default'] = _ember['default'].Helper.helper(pluralize);
});